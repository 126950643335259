import dynamic from 'next/dynamic';
import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { useDataContext } from '~/contexts/Data';
import { Objkt } from '~/types';
import StatutText from './StatusText';
import TokenGrid from './TokenGrid';
import TokenListLoader from './TokenListLoader';

const Token = dynamic(() => import('./Token'), {
  ssr: false,
});

const TokenList: React.FC<{
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  tokens: Objkt[];
  walletOf?: string;
}> = ({
  isLoading,
  error,
  tokens = [],
  walletOf,
}) => {
  const { layout } = useDataContext();
  const filteredTokens = React.useMemo(() => uniqBy(tokens.filter(Boolean), (o) => o.id), [JSON.stringify(tokens)]);
  return (
    isLoading ? <TokenListLoader />
      : error ? <StatutText>Something went wrong</StatutText>
        : (
          <TokenGrid $layout={ layout }>
            {
              filteredTokens.map((t) => (
                <Token
                  key={ `token.${layout}.${t.id}` }
                  walletOf={ walletOf }
                  objkt={ t }
                  layout={ layout }
                />
              ))
            }
          </TokenGrid>
        ));
};

export default TokenList;
