import React from 'react';
import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import LoadIcon from './LoadIcon';
import ShowMoreButton from './ShowMoreButton';
import StatusText from './StatusText';

const FetchMoreButton: React.FC<{
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
}> = ({
  isFetchingNextPage,
  fetchNextPage,
}) => {
  const fetchMoreButtonRef = React.useRef();
  useIntersectionObserver({
    target: fetchMoreButtonRef,
    onIntersect: fetchNextPage,
  });
  return isFetchingNextPage ? (
    <StatusText $center style={ { padding: 15 } }>
      <LoadIcon $animating />
    </StatusText>
  ) : (
    <ShowMoreButton ref={ fetchMoreButtonRef } onClick={ isFetchingNextPage ? null : () => fetchNextPage() }>
      Show more
    </ShowMoreButton>
  );
};

export default FetchMoreButton;
