/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import Checkbox from './Checkbox';
import Modal, { ModalHeader, ModalCloseButton, ModalBody } from '~/components/Modal';
import Input from './Input';
import { MIMETYPE_LIST } from '~/utils/mime';
import FlexRow from './FlexRow';
import { MimeTypeItemType } from '~/types';
import useBreakpoint from '~/hooks/useBreakpoint';
import { useFilterQueryParams, useSetFilterQueryParams } from '~/hooks/useFilter';
import ObjktSortButton from './ObjktSortButton';
import { xtz } from '~/utils';

const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
  margin-bottom: 5px;
  display: inline-flex;
`;

const FilterContainer = styled.div`
  text-align: left;
`;

const MimeTypeRow = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const MimeTypeHelper = styled.span`
  color: ${({ theme }) => theme.colors.textLight};
`;

const MimeTypeItem: React.FC<MimeTypeItemType> = ({ label, value, types = [] }) => {
  const { mime } = useFilterQueryParams();
  const setFilterQueryParams = useSetFilterQueryParams();
  const handleChange = () => setFilterQueryParams({
    mime: mime.includes(value)
      ? (mime as string[]).filter((m_) => m_ !== value).sort().join(',')
      : [...(mime as string[]), value].sort().join(','),
  });
  return (
    <MimeTypeRow onClick={ handleChange }>
      <StyledCheckbox
        key={ `type.${value}` }
        label={ label }
        value={ value }
        onChange={ handleChange }
        checked={ mime.includes(value) }
      />
      <MimeTypeHelper>
        {types.join(' | ') || ''}
      </MimeTypeHelper>
    </MimeTypeRow>
  );
};

const SectionTitle = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
`;

const ObjktFilterButton: React.FC<{ className?: string; }> = ({ className }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const {
    mime,
    minSupply,
    maxSupply,
    minPrice,
    maxPrice,
    isForSale,
    showBanned,
  } = useFilterQueryParams();
  const setFilterQueryParams = useSetFilterQueryParams();
  const getFilterCount = (): number => {
    let count = 0;
    if (mime.length > 0) count += 1;
    if (minSupply || maxSupply) count += 1;
    if (minPrice || maxPrice) count += 1;
    if (isForSale) count += 1;
    if (showBanned) count += 1;
    return count;
  };
  const filterCount = getFilterCount();
  const handleChangeForSale = () => setFilterQueryParams({ isForSale: !isForSale });
  const handleChangeShowBanned = () => setFilterQueryParams({ showBanned: !showBanned });
  const handleChangeMinPrice = (e) => setFilterQueryParams({ minPrice: +e.target.value });
  const handleChangeMaxPrice = (e) => setFilterQueryParams({ maxPrice: +e.target.value });
  const handleChangeMinSupply = (e) => setFilterQueryParams({ minSupply: +e.target.value });
  const handleChangeMaxSupply = (e) => setFilterQueryParams({ maxSupply: +e.target.value });
  const breakpoint = useBreakpoint();
  return (
    <>
      <Button className={ className } onClick={ () => setIsOpen(true) } style={ { marginLeft: 15 } }>
        {`Filter${filterCount ? ` (${filterCount})` : ''}`}
      </Button>
      <Modal isOpen={ isOpen } handleClose={ () => setIsOpen(false) }>
        <ModalHeader>
          <h2 style={ { margin: 0 } }>
            Filter objkts
          </h2>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 20, textAlign: 'center' } }>
          <FilterContainer>
            {
              breakpoint === 'mobile' ? (
                <div style={ { marginBottom: 20 } }>
                  <SectionTitle>Sort objkts by</SectionTitle>
                  <ObjktSortButton />
                </div>
              ) : null
            }
            <div style={ { marginBottom: 20 } }>
              <Button $primary={ isForSale } onClick={ handleChangeForSale }>
                Buy now
              </Button>
              <Button $primary={ showBanned } onClick={ handleChangeShowBanned } style={ { marginLeft: 15 } }>
                Show banned artists
              </Button>
            </div>
            <FlexRow style={ { marginBottom: 20 } }>
              <div style={ { marginRight: 15 } }>
                <SectionTitle>
                  Min price (
                  {xtz}
                  )
                </SectionTitle>
                <Input
                  type="number"
                  step="0.01"
                  placeholder="Min price"
                  value={ minPrice }
                  onChange={ handleChangeMinPrice }
                  style={ { width: 150 } }
                />
              </div>
              <div>
                <SectionTitle>
                  Max price (
                  {xtz}
                  )
                </SectionTitle>
                <Input
                  type="number"
                  step="0.01"
                  placeholder="Max price"
                  value={ maxPrice }
                  onChange={ handleChangeMaxPrice }
                  style={ { width: 150 } }
                />
              </div>
            </FlexRow>
            <FlexRow style={ { marginBottom: 20 } }>
              <div style={ { marginRight: 15 } }>
                <SectionTitle>Min editions</SectionTitle>
                <Input
                  type="number"
                  min={ 0 }
                  step="1"
                  placeholder="Min editions"
                  value={ minSupply }
                  onChange={ handleChangeMinSupply }
                  style={ { width: 150 } }
                />
              </div>
              <div>
                <SectionTitle>Max editions</SectionTitle>
                <Input
                  type="number"
                  min={ 0 }
                  step="1"
                  placeholder="Max editions"
                  value={ maxSupply }
                  onChange={ handleChangeMaxSupply }
                  style={ { width: 150 } }
                />
              </div>
            </FlexRow>
            {/*
            <div style={ { marginBottom: 5, fontWeight: 'bold' } }>Filter by MIME type</div>
            {
              Object.values(MIMETYPE).sort().map((v) => (
                <StyledCheckbox
                  key={ `mime.${v}` }
                  label={ v }
                  value={ v }
                  onChange={ () => handleChangeMime(v) }
                  checked={ mime.includes(v) }
                />
              ))
            }
            */}
            <SectionTitle>Filter by type</SectionTitle>
            {
              MIMETYPE_LIST.map((item) => (
                <MimeTypeItem key={ `type.${item.value}` } { ...item } />
              ))
            }
          </FilterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ObjktFilterButton;
