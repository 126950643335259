import { useDataContext } from '~/contexts/Data';
import TokenGrid from './TokenGrid';
import TokenLoader from './TokenLoader';

export const TokenListLoaderItems = () => (
  <>
    {
      Array.from({ length: 20 }).map((_, index) => index).map((index) => (
        <TokenLoader key={ `token.${index}` } />
      ))
    }
  </>
);

const TokenListLoader = () => {
  const { layout } = useDataContext();
  return (
    <TokenGrid $layout={ layout }>
      <TokenListLoaderItems />
    </TokenGrid>
  );
};

export default TokenListLoader;
