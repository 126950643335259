import { FiGrid } from 'react-icons/fi';
import { BsList } from 'react-icons/bs';
import { BiLineChart } from 'react-icons/bi';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import Button, { buttonHeight } from './Button';
import { useDataContext } from '~/contexts/Data';
import 'tippy.js/animations/scale.css';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: -1px;
  button {
    margin-right: 1px;
  }
  svg {
    transform: scale(1.5);    
  }
`;

const ObjktGridSelect = () => {
  const { layout, setLayout } = useDataContext();
  return (
    <Container>
      <Tippy content="Gallery layout" delay={ 600 } animation="scale">
        <Button onClick={ () => setLayout('gallery') } $primary={ layout === 'gallery' } style={ { width: buttonHeight } }>
          <FiGrid />
        </Button>
      </Tippy>
      <Tippy content="Default layout" delay={ 600 } animation="scale">
        <Button onClick={ () => setLayout('regular') } $primary={ layout === 'regular' } style={ { width: buttonHeight } }>
          <BsList />
        </Button>
      </Tippy>
      <Tippy content="Advanced layout" delay={ 600 } animation="scale">
        <Button onClick={ () => setLayout('advanced') } $primary={ layout === 'advanced' } style={ { width: buttonHeight } }>
          <BiLineChart />
        </Button>
      </Tippy>
    </Container>
  );
};

export default ObjktGridSelect;
