import styled from 'styled-components';
import { media } from '~/styles/media';

const StatusText = styled.div<{ $center?: boolean; }>`
  display: block;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  padding: 10px;
  ${media.mobile`
    margin: 0 15px;
  `}
`;

export default StatusText;
