import styled from 'styled-components';
import useBreakpoint from '~/hooks/useBreakpoint';
import { media } from '~/styles/media';
import { formatAmount } from '~/utils';
import { buttonHeight } from './Button';
import FlexRow from './FlexRow';
import { headerHeight } from './Layout';
import ObjktFilterButton from './ObjktFilterButton';
import ObjktGridSelect from './ObjktGridSelect';
import ObjktSortButton from './ObjktSortButton';

export const ObjktFilterRowContainer = styled(FlexRow)`
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
  min-height: ${buttonHeight}px;
  position: sticky;
  top: ${headerHeight}px;
  z-index: 1;
  transition: background 300ms ease, color 300ms ease;
  padding: 15px 0;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.backgroundTranslucent};
  backdrop-filter: blur(20px);
  transform: translateZ(0px);
`;

export const CountContainer = styled(FlexRow)`
  flex: 1;
  font-weight: bold;
  ${media.mobile`
    margin-bottom: 5px;
  `}
`;

export const RightSection = styled(FlexRow)`
  justify-content: flex-end;
`;

const ObjktFilterRow: React.FC<{
  count: number;
  countLoading?: boolean;
  address?: string;
  noSort?: boolean;
}> = ({
  count,
  countLoading,
  address,
  noSort,
}) => {
  const breakpoint = useBreakpoint();
  return (
    <ObjktFilterRowContainer>
      <CountContainer>
        {countLoading ? 'Loading count...' : `${formatAmount(count, 0)} objkt${count > 1 ? 's' : ''}`}
      </CountContainer>
      <RightSection>
        <ObjktGridSelect />
        <ObjktFilterButton />
        {
          noSort || breakpoint === 'mobile' ? null : (
            <div style={ { marginLeft: 15 } }>
              <ObjktSortButton address={ address } />
            </div>
          )
        }
      </RightSection>
    </ObjktFilterRowContainer>
  );
};

export default ObjktFilterRow;
